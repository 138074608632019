<h2>Welcome to ircnet.info!</h2>
<br/>
<p>This website offers the following services:</p>
<ul>
  <li><a [routerLink]="['/servers']">Server List</a> - lists all servers (updated every 10 seconds)</li>
  <li><a [routerLink]="['/channels']">Channel List</a> - lists all public channels (live data)</li>
  <li><a [routerLink]="['/i-line']">I-Line Lookup</a> - checks which servers you can connect to</li>
</ul>
<br>
<div class="alert alert-success" style="display:inline-block;" role="alert">
  We are now testing hostname cloaks with SASL!
  <br>
  For more information, visit <a href="https://www.ircnet.com/sasl">https://www.ircnet.com/sasl</a>
</div>
<br>
<br>
